import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/servicii/software-la-comanda/">
                        Dezvoltare de software la comandă
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/testare-asigurarea-calitatii/">
                        Testare și asigurarea calității
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/mentenanta-aplicatii/">
                       Management și mentenanță aplicații
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/transformare-digitala/" className="active">
                        Transformare digitală
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/dezvoltare-migrare-cloud">
                        Dezvoltare și migrare cloud
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/management-proiect">
                       Management de proiect
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/training-it">
                       Training IT personalizat
                    </Link>
                </li>
                <li>
                    <Link to="/servicii/securitate-cibernetica">
                       Securitate cibernetică
                    </Link>
                </li>
            </ul>

            <div className="services-contact-info">
                <h3>Date de contact</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Telefon:</span>
                        <a href="tel:+40734580793">+40 734 580 793</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Locație:</span>
                        Oradea, România
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:info@verticaldigital.ro">info@verticaldigital.ro</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar