import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții inovatoare</span>
                            <h3>Despre transformarea digitală</h3>
                            <p>Transformarea digitală este procesul de utilizare a tehnologiilor digitale pentru a schimba fundamental modul în care o organizație își desfășoară activitatea și oferă valoare clienților săi. La Vertical Digital, ne specializăm în ajutarea organizațiilor să înceapă inițiative de transformare digitală pentru a spori inovația, a îmbunătăți eficiența și a crește competitivitatea.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>Procesul nostru de transformare digitală începe cu o evaluare amănunțită a capacităților și provocărilor actuale ale organizației dvs. Lucrăm cu dvs. pentru a vă defini obiectivele de transformare digitală și a dezvolta o hartă de drum pentru atingerea lor.</p>
                                        <p>Apoi, vă ajutăm să implementați tehnologiile și procesele necesare pentru a susține transformarea digitală. Acest lucru poate include implementarea de soluții bazate pe cloud, automatizarea proceselor de afaceri și adoptarea de noi instrumente și platforme digitale.</p>
                                        <p>În timpul procesului, oferim suport și orientare continuă pentru a asigura succesul eforturilor de transformare digitală. Lucrăm cu dvs. pentru a monitoriza progresul, a identifica orice provocări și a face orice ajustări necesare pentru a vă asigura că puteți atinge rezultatele dorite.</p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați ajutorării organizațiilor să reușească în era digitală. Echipa noastră de experți are cunoștințele și experiența necesare pentru a vă ghida prin procesul de transformare digitală, de la început până la sfârșit. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta să sporiți inovația, să îmbunătățiți eficiența și să creșteți competitivitatea.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Kotlin</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Docker</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent