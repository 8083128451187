import React from 'react'
import { Link } from 'gatsby'
import icon1 from '../../../assets/images/services/service-icon1.png'
import icon2 from '../../../assets/images/services/service-icon2.png'
import icon3 from '../../../assets/images/services/service-icon3.png'

const RelatedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Mai multe servicii care v-ar putea interesa</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/dezvoltare-migrare-cloud/">
                                    Dezvoltare și migrare cloud
                                </Link>
                            </h3>
                            <p>Echipa noastră de ingineri cloud vă ajută organizația să atingă o agilitate mai mare, o eficiență mai bună a costurilor și o productivitate mai crescută prin intermediul serviciilor noastre profesionale cloud și a expertizei noastre în DevOps.</p>

                            <Link to="/servicii/dezvoltare-migrare-cloud/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/management-proiect/">
                                     Management de proiect
                                </Link>
                            </h3>
                            <p>Eficientizați procesul de dezvoltare software și duceți proiectele la bun sfârșit cu echipa noastră experimentată de management de proiect.</p>

                            <Link to="/servicii/management-proiect/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/servicii/securitate-cibernetica/">
                                    Securitate cibernetică
                                </Link>
                            </h3>
                            <p>Protejați-vă afacerea și datele sensibile în era digitală cu serviciile noastre de cibersecuritate de înaltă calitate.</p>

                            <Link to="/servicii/securitate-cibernetica/" className="read-more-btn">
                                Vezi Detalii <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RelatedServices